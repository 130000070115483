import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { portal_url } from "../utils/Api";

const Logout = () => {
  useEffect(() => {
    doLogout(routeParams.token);
  }, []);

  //========== VARIABLE ============

  const routeParams = useParams();
  let getError = "";

  //========== METHODS ============
  const doLogout = async (token) => {
    await Axios.post(
      `${portal_url}/api/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        window.location = `/`;
      })
      .catch((error) => {
        if (typeof error.response.data.message !== "undefined") {
          getError = error.response.data.message;
        }
        window.location = `/`;
      });
  };

  return <></>;
};

export default Logout;
