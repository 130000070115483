import React, { useState, useEffect } from "react";
import Axios from "axios";
// import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
// import { app_store, play_store, huawei_store } from "../images";
import { portal_url } from "../utils/Api";
import { Url_V1, Url_V2 } from "../utils/config";
import { Base64 } from "js-base64";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Banner from "../components/Banner";
import { logo_ss, logo_korlantas } from "../images";

const Login = () => {
  useEffect(() => {
    localStorage.setItem("apiToken", "");
    localStorage.setItem("username", "");
  }, []);

  // function getWindowSize() {
  //   const { innerWidth, innerHeight } = window;
  //   return { innerWidth, innerHeight };
  // }

  //========== VARIABLE ============

  // const [windowSize] = useState(getWindowSize());
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isLogin, setIsLogin] = useState(false);
  let data = [];
  let getError = "Processing to login";

  //========== METHODS ============
  const doLogin = async () => {
    setIsLogin(true);
    await Axios.post(`${portal_url}/api/single_login`, {
      username: username,
      password: password,
    })
      .then((res) => {
        if (res.data.platform === "gps_id_v1") {
          toastNotif("success", "Login Successfully");
          setTimeout(() => {
            data = JSON.stringify({
              RequestUserName: username,
              RequestPassword: password,
              RequestLanguage: "en",
            });

            window.location = `${Url_V1}/log.php?apiKey=${Base64.btoa(data)}`;
          }, 2000);
        } else {
          if (res.data.status === true) {
            data = res.data.message.data;
            getProfil();
          }
        }
      })
      .catch((error) => {
        if (typeof error.response.data.message !== "undefined") {
          getError = error.response.data.message;
        }
        toastNotif("error", getError);
        setIsLogin(false);
      });
  };

  const getProfil = async () => {
    await Axios.get(`${portal_url}/api/profile`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((res) => {
        setIsLogin(false);
        const myApp = res.data.message.data;
        if (myApp.apps.apps.indexOf(2) !== -1) {
          toastNotif("success", "Login Successfully");
          setTimeout(() => {
            window.location = `${Url_V2}/login/${data.token}?isGenerate=${data.isGenerate}`;
          }, 2000);
        } else {
          toastNotif("error", "Don't have access to tracking system");
        }
      })
      .catch((error) => {
        if (typeof error.response.data.message !== "undefined") {
          getError = error.response.data.message;
        }
        toastNotif("error", getError);
        setIsLogin(false);
      });
  };

  // const doDemo = async () => {
  //   await Axios.post(`${portal_url}/api/demo_login`, {
  //     apikey: "1b1f5d489651898e2519736efacc8ac2",
  //   })
  //     .then((res) => {
  //       toastNotif("success", "Login demo Successfully");
  //       if (res.data.status === true) {
  //         setTimeout(() => {
  //           let data = res.data.message.data;
  //           window.location = `${Url_V2}/login/${data.token}`;
  //         }, 2000);
  //       }
  //     })
  //     .catch((error) => {
  //       if (typeof error.response.data.message !== "undefined") {
  //         getError = error.response.data.message;
  //       }
  //       toastNotif("error", getError);
  //     });
  // };

  const toastNotif = (type, message) => {
    toast(message, {
      position: "top-right",
      autoClose: 1000,
      type: type,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      doLogin();
    }
  };

  // const showPlayStore = () => {
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=com.superspring.gpsid",
  //     "_blank"
  //   );
  // };

  // const showAppStore = () => {
  //   window.open(
  //     "https://apps.apple.com/id/app/gps-id-dari-super-spring/id1119572414",
  //     "_blank"
  //   );
  // };

  // const showHuaweiStore = () => {
  //   window.open("https://appgallery.huawei.com/app/C108220087", "_blank");
  // };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="body">
      {/* <Navbar /> */}

      <div className="container">
        <div className="card card-style">
          <div class="row justify-content-start mb-3">
            <div class="col-12">
              <center>
                <h3>Welcome back!</h3>
                <h6 style={{ color: "#7a7a7a" }}>Sign in to continue</h6>
              </center>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <div className="ms-2 me-2">
                <label
                  htmlFor="exampleInputUsername"
                  className="form-label text-label-username"
                >
                  Username
                </label>
                <input
                  type="text"
                  className="form-control text-input"
                  id="exampleInputUsername"
                  placeholder="Input your username"
                  style={{ fontSize: "13px" }}
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyDown={(e) => _handleKeyDown(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="ms-2 me-2">
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label text-label-password"
                >
                  Password
                </label>
                <div className="input-group">
                  <input
                    type={passwordType}
                    className="form-control text-input"
                    id="exampleInputPassword1"
                    placeholder="Input your password"
                    style={{ fontSize: "13px" }}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => _handleKeyDown(e)}
                  />
                  <span
                    className="input-group-text px-3"
                    id="basic-addon2"
                    style={{
                      backgroundColor: "white",
                      marginTop: "-10px",
                      height: "55px",
                    }}
                    onClick={togglePassword}
                  >
                    {passwordType === "password" ? (
                      <FontAwesomeIcon icon="eye-slash" />
                    ) : (
                      <FontAwesomeIcon icon="eye" />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-primary btn-submit"
                onClick={() => doLogin()}
                disabled={isLogin}
              >
                {!isLogin ? (
                  "Login"
                ) : (
                  <>
                    <span
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                    <span role="status">&nbsp;&nbsp;Loading...</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
